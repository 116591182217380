import {GetRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import ApiError from "../Models/ApiError"
import {getPath, getNewPath} from "./ApiUrl";

const GetPlansStripe = async () => {
  let apiResponse;
  if (process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(getNewPath("payment/prices"));
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.isValid()) {
    return apiResponse.body.data;
  } else {
    let message = `Something went wrong. Please contact support@profilebud.com. Error: ${apiResponse.error}`;
    return new ApiError(message);
  }
};

function dummyPlans() {
  return {"data":[{"id":1,"stripe_price_id":"price_1I1ZufC0dnUYV64pvwFiVUoC","name":"Premium Monthly","price_in_cents":1500.0,"time_in_month":1,"created_at":"2020-12-24T06:23:03.783Z","updated_at":"2020-12-24T06:23:03.783Z"},{"id":2,"stripe_price_id":"price_1I1ZvDC0dnUYV64pEvm8gpS2","name":"Premium Yearly","price_in_cents":9900.0,"time_in_month":12,"created_at":"2020-12-24T06:23:03.793Z","updated_at":"2020-12-24T06:23:03.793Z"}],"success":true,"error":null}
}

export default GetPlansStripe;
