import React from "react";
import Layout from "../Layout";
import Product from "./Product";
import SearchBox from "./SearchBox";
import ReactGA, {event} from 'react-ga'
import Steps from "./Steps";
import ValidateCoupon from "../../Common/ApiCalls/ValidateCoupon";
import SendNotification from "../../Common/Utils/SendNotification";
import NotificationTypeEnum from "../../Common/Models/NotificationTypeEnum";
import {TotalPriceCard} from "./TotalPriceCard";
import {CheckSquareFilled} from '@ant-design/icons'

class Prices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plans: this.props.plans,
      selectedPlanId: null,
      accounts: [],
      maxUsers: 1,
      planName: '',
      months: 1,
      coupon_code: '',
      valid_coupon: false,
      discount_percentage: 0,
      price: 0,
      init: true
    };
  }

  handleClick = (key) => {
    const plans = this.props.plans;
    for(let i=0; i<plans.length ; i++ ) {
      if(plans[i]['id'] === key) {
        this.setState({
          selectedPlanId: plans[i]['stripe_price_id'],
          planName: plans[i]['name'],
          price: plans[i]['price_in_cents'],
          months: plans[i]['time_in_month']
        });
        ReactGA.event({
          category: `Price Card ${plans[i]['name']}`,
          action: 'Pricing Selected'
        })
        if(this.state.accounts.length > plans[i]['accounts']) {
          this.setState({
            accounts: this.state.accounts.slice(0, plans[i]['accounts'])
          })
        }
        break;
      }
    }
  }

  nextPage = () => {
    this.props.handlePriceChange(this.state.selectedPlanId, this.state.price, this.state.planName)
    let accounts = this.state.accounts;
    let accountDetails = [];
    for(let i=0;i<accounts.length;i++) {
      let user = {
        id: accounts[i]['id'],
        username: accounts[i]['username']
      }
      accountDetails.push(user)
    }
    if(this.state.valid_coupon) {
      this.props.handleCoupon(this.state.coupon_code, this.state.discount_percentage)
    }
    this.props.handleUsers(accountDetails)
    this.props.nextPage()
  }
  handleAccounts = (accounts) => {
    let accountDetails = [];
    for(let i=0;i<accounts.length;i++) {
      let user = {
        id: accounts[i]['id'],
        username: accounts[i]['username'],
        avatar: accounts[i]['avatar']
      }
      ReactGA.event({
        category: 'Search',
        action: 'Select User',
        label: `Username: ${accounts[i]['username']}`
      })
      accountDetails.push(user)
    }
    this.setState({accounts: accountDetails})
  }
  isValid = () => {
    return !!(this.state.selectedPlanId && (this.state.accounts.length <= this.state.maxUsers && (this.state.accounts.length) ));
  }

  enterPressed = (e) => {
    if(e.which===13 || e.keyCode===13) {
      this.ValidateCouponFunction()
    }
  }

  componentDidUpdate() {
    if(this.state.init && this.props.plans.length > 0) {
      this.handleClick(this.props.plans[0].id);
      this.setState({init: false})
    }
  }

  ValidateCouponFunction = () => {
    ValidateCoupon(this.state.coupon_code)
      .then(r => {
        if(r.is_valid) {
          SendNotification(NotificationTypeEnum.Success, r.percentage_discount + '% Discount Applied')
          this.setState({valid_coupon: true, discount_percentage: r.percentage_discount})
        } else {
          SendNotification(NotificationTypeEnum.Failure, 'Invalid Coupon')
        }
      })
  }
  removeCoupon = () => {
    this.setState({coupon_code: '', valid_coupon: false, discount_percentage: 0})
  }

  render() {
    let plans = this.props.plans;
    return(
      <Layout sign >

        <Steps current={0} />

        <div className="title is-uppercase" id='top'>
          Select a plan
        </div>

        <div className="columns is-multiline">

          <div className="column is-two-thirds-tablet">

            <div className="columns is-multiline is-centered is-mobile is-variable is-8-widescreen is-3-tablet is-2-mobile">
              {plans.map((item, index)=> (
                <Product
                  selected={item.stripe_price_id === this.state.selectedPlanId}
                  key={index}
                  product={item}
                  handleClick={this.handleClick}
                />
              ))}
            </div>

            <SearchBox
              handleAccounts={this.handleAccounts}
              maxUsers={this.state.maxUsers}
              selectedMusicians={this.state.accounts}
            />

          </div>

          <div className="column is-4-tablet is-3-widescreen is-one-third-tablet is-offset-1-widescreen">

            <div>
              <div className="has-text-right" style={{padding: '0 24px 0 24px'}}>
                <div onClick={()=>this.setState({discount_pop: !this.state.discount_pop})} className="is-uppercase has-text-weight-bold">
                  Coupon Code
                </div>
              </div>

              {!this.state.valid_coupon ? (
                <div className="field">
                  <div className="control" style={{padding: '10px 24px 0 24px', textAlign: 'end'}}>
                    <input
                      style={{padding: '12px', width: 'auto'}}
                      className="input is-small"
                      value={this.state.coupon_code}
                      onChange={e=>this.setState({coupon_code: (e.target.value).toUpperCase()})}
                      onKeyPress={e=>this.enterPressed(e)}
                    />
                    &nbsp;&nbsp;
                    <CheckSquareFilled
                      onClick={this.ValidateCouponFunction}
                      className="title cursor-pointer"
                    />
                  </div>
                </div>
              ) : (
                <div style={{padding: '10px 24px 0 24px'}}>
                  <div className="tags has-addons" style={{justifyContent: 'flex-end'}}>
                    <span className="tag is-large is-danger">
                      {this.state.coupon_code}
                    </span>
                    <a onClick={()=>this.removeCoupon()} className="tag is-large is-delete" />
                  </div>
                </div>
              )}
            </div>

            <div className="flex-end">
              <TotalPriceCard
                coupon={this.state.valid_coupon ? this.state.coupon_code : false}
                valid={this.isValid()}
                months={this.state.months}
                plan_name={this.state.planName}
                nextPageFunction={this.nextPage}
                nextExist={true}
                discount_percentage={this.state.discount_percentage}
                price={this.state.price}
              />
            </div>

          </div>

          </div>

      </Layout>
    )
  }
}

export default Prices