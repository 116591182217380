import React from 'react';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';

class SelectedItemCard extends React.Component {
	render() {
		const {
			type, avatar, title, subtitle, onRemove = () => {},
		} = this.props;

		return (
			<div className="column is-two-thirds-tablet is-one-third-desktop selected-item">
					<div className="columns is-mobile is-vcentered">
						<div className="avatar selected-item-image">
							{avatar ? <img src={avatar} alt='avatar' /> : ''}
						</div>
						<div className="info column is-narrow">
							<div className="subtitle">{subtitle}</div>
						</div>
						<div className="column is-narrow">
							<div className="close icon-24" onClick={onRemove}>
								<CloseCircleFilled />
							</div>
						</div>
					</div>
			</div>
		);
	}
}

export default SelectedItemCard;
