import React from 'react'
import './Components/all.sass'
import {withRouter} from 'react-router-dom'
import Home from "./Components/Home";
import ErrorBoundary from "./Common/Models/ErrorBoundary";

function App() {
  const WrapperHome = withRouter(props => (
    <ErrorBoundary>
      <Home {...props} />
    </ErrorBoundary>
  ))
  return <WrapperHome />
}

export default App;
