import React, {useState} from "react";
import Layout from "../Layout";
import ReactGA from 'react-ga'
import {CardElement, Elements, useStripe, useElements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js/pure";
import SendNotification from "../../Common/Utils/SendNotification";
import NotificationTypeEnum from "../../Common/Models/NotificationTypeEnum";
import StepsComponent from "./Steps";
import {TotalPriceCard} from "./TotalPriceCard";
import ReactFlagsSelect from "react-flags-select";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
let stripePromise;
if(process.env.NODE_ENV === 'development') {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY)
}
else {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  if (!process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
    console.error('**Stripe publishable key environment variable not set**');
    console.error(
      '**Add an environemnt variable REACT_APP_STRIPE_PUBLISHABLE_KEY**'
    );
    console.error('**Replace .env.example with .env and **');
  }
}

const CARD_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      color: '#fff',
      fontFamily:
        '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
      '::placeholder': {
        color: '#a0aec0',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
}

const CheckoutForm = ({email, password, price_id, beat_accounts, nextPage, handleAccount, goBack, discount_percentage, price, coupon_code, plan_name}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [subscribing, setSubscribing] = useState(false);
  const [accountInformation, setAccountInformation] = useState([])
  const [latestInvoicePaymentIntentStatus, setlatestInvoicePaymentIntentStatus] = useState(null)
  const [latestInvoiceId, setlatestInvoiceId] = useState(null)
  const [billingDetails, setBillingDetails] = useState({
    full_name: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'US'
  })

  function handleCustomerActionRequired({
                                          subscription,
                                          invoice,
                                          priceId,
                                          paymentMethodId,
                                          isRetry,
                                        }) {
    console.log(subscription)
    if (subscription && subscription.status === 'active') {
      // subscription is active, no customer actions required.
      setAccountInformation(subscription)
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    console.log(invoice, subscription)
    let paymentIntent;
    if(subscription) paymentIntent = subscription.latest_invoice.payment_intent;
    if(invoice) {
      paymentIntent = invoice.payment_intent
    }

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            //SendNotification('Card was declined', result.error.message)
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to invoice.payment_succeeded. This webhook endpoint
              // returns an Invoice.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        });
    } else {
      setAccountInformation(subscription)
      // No customer action needed
      return { subscription, priceId, paymentMethodId };
    }
  }

  function retryInvoiceWithNewPaymentMethod({ paymentMethodId, invoiceId }) {
    let body = {
      payment_method_id: paymentMethodId,
      invoice_id: invoiceId,
      email,
      password,
      full_name: billingDetails.full_name,
      beat_account: beat_accounts[0],
      address: billingDetails
    }
    if(coupon_code!=='') body['coupon'] = coupon_code
    return (
      fetch('https://api.beatshine.com/beat/api/v2/payment/retry_invoice', {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if(response.ok) {
            return response.json();
          }
          else {
            setSubscribing(false)
            SendNotification('Error while attaching card to your account', 'Check your Card Details and Retry', true)
            throw response.errors;
          }
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result.data;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: price_id,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handleCustomerActionRequired)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          console.log(error);
          if(error.error) {
            error = error.error
          }
          SendNotification(NotificationTypeEnum.Failure, error.message)
          ReactGA.event({
            category: 'Retry Subscription Failed',
            action: 'subscription failed ',
            label: `${error.message}`
          })
          // An error has happened. Display the failure to the user here.
          setSubscribing(false);
        })
    );
  }

  function handlePaymentMethodRequired({
                                         subscription,
                                         paymentMethodId,
                                         priceId,
                                       }) {
    if (subscription.status === 'active') {
      setAccountInformation(subscription)
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      setlatestInvoicePaymentIntentStatus(subscription.latest_invoice.payment_intent.status)
      setlatestInvoiceId(subscription.latest_invoice.id)
      throw { error: { message: 'Your card was declined.' } };
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function onSubscriptionComplete(result) {

    console.log(result)
    ReactGA.event({
      category: 'Subscription Complete',
      action: 'Subscription Complete'
    })
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    setlatestInvoiceId(null)
    setlatestInvoicePaymentIntentStatus(null)
    // clearCache();
    setAccountInformation(result);
    handleAccount(result)
    nextPage();

    // Change your UI to show a success message to your customer.
    // onSubscriptionSampleDemoComplete(result);
    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
  }

  function createSubscription({ paymentMethodId }) {
    let body = {
      email,
      password,
      full_name: billingDetails.full_name,
      payment_method_id: paymentMethodId,
      price_id,
      beat_account: beat_accounts[0],
      address: billingDetails
    }
    if(coupon_code!=='') body['coupon'] = coupon_code
    return (
      fetch('https://api.beatshine.com/beat/api/v2/payment/subscription', {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if(response.ok) {
            return response.json()
          }
          else {
            setSubscribing(false)
            SendNotification('Error while attaching card to your account', 'Check your Card Details and Retry', true)
            throw response.errors;
          }
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.errors) {
            // The card had an error when trying to attach it to a customer
            setSubscribing(false)
            SendNotification('Error while attaching card to your account', 'Check your Card Details and Retry', true)
            throw result;
          }
          return result.data;
        })
        // Normalize the result to contain the object returnedbillingAddress
        // by Stripe. Add the addional details we need.
        .then((result) => {
          console.log(result)
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription: result,
            paymentMethodId: paymentMethodId,
            priceId: price_id,
          };
        })
        // Some payment methods require a customer to do additional
        // authentication with their financial institution.
        // Eg: 2FA for cards.
        .then(handleCustomerActionRequired)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail. You will
        // get a requires_payment_method error.
        .then(handlePaymentMethodRequired)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          if(error.error) {
            error = error.error
          }
          ReactGA.event({
            category: 'Create Subscription Error',
            action: 'Error in subsciption',
            label: `${error.message}`
          })
          SendNotification(NotificationTypeEnum.Failure, error.message)
          setSubscribing(false);
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          console.log(error);
          // displayError(error);
        })
    );
  }

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    setSubscribing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      SendNotification(NotificationTypeEnum.Failure,'Fill Card Details to continue')
      setSubscribing(false)
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    // If a previous payment was attempted, get the lastest invoice
    // Stored In state latestInvoice

    if (error) {
      console.log('[error]', error);
      SendNotification(NotificationTypeEnum.Failure,error.message)
      setSubscribing(false)
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      const paymentMethodId = paymentMethod.id;
      if(latestInvoicePaymentIntentStatus === 'requires_payment_method') {
        // Update the payment method and retry invoice method
        retryInvoiceWithNewPaymentMethod({
          paymentMethodId: paymentMethodId,
          invoiceId: latestInvoiceId
        })
      } else {
        createSubscription({
          paymentMethodId: paymentMethod.id
        });
      }
    }
  };

  return(
    <Layout >

      <StepsComponent current={2} goBack={goBack} />

      <div className="title">
        Payment
      </div>

      <form className="columns" onSubmit={handleSubmit}>

        <div className="column is-one-third-tablet">

          <div className="subtitle">
            Personal Details
          </div>

          <div className="field">
            <div className="control">
              <input
                className="input is-spaced"
                required
                placeholder="Full Name"
                onChange={e=>setBillingDetails({...billingDetails, full_name: e.target.value})}
              />
            </div>
          </div>

          <div className="field">
            <div className="control">
              <input
                className="input"
                required
                placeholder="Address Line 1"
                onChange={e=>setBillingDetails({...billingDetails, line_1: e.target.value})}
              />
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="control">
              <input
                className="input"
                required
                placeholder="City"
                onChange={e=>setBillingDetails({...billingDetails, city: e.target.value})}
              />
            </div>
            <div className="padded-div" />
            <div className="control">
              <input
                className="input"
                required
                placeholder="State"
                onChange={e=>setBillingDetails({...billingDetails, state: e.target.value})}
              />
            </div>
          </div>

          <div className="field">
            <div className="control has-text-centered">
              <input
                style={{maxWidth: '200px'}}
                className="input"
                required
                placeholder="Zip Code"
                onChange={e=>setBillingDetails({...billingDetails, postal_code: e.target.value})}
              />
            </div>
          </div>

          <div className="field">
            <div className="control has-text-centered">
                <ReactFlagsSelect
                        defaultCountry={billingDetails.country}
                        searchable
                        searchPlaceholder="Search for a country"
                        onSelect={(cc)=>setBillingDetails({...billingDetails, country: cc})}
                />
              </div>
          </div>

        </div>

        <div className="column is-one-third-tablet">

          <div className="subtitle">
            Card Details
          </div>

          <div className="field" id="card-field">
            <div className="control">
              <div id="card-element">
                <CardElement options={CARD_OPTIONS}/>
                <div
                  id="card-element-errors"
                  className="has-text-grey-light"
                  role="alert"
                />
              </div>
            </div>
          </div>

          <div className="field">
            <div className="control">
              <button className="button is-fullwidth is-large is-danger" type="submit" id="submit-premium">
                <div>{subscribing ? 'Subscribing...' : "Subscribe"}</div>
              </button>
            </div>
          </div>

        </div>
{/*
        <div className='column is-one-third-tablet' style={{display: 'grid'}}>
          <div className="flex-end">
            <TotalPriceCard
              coupon={coupon_code!==""}
              plan_name={plan_name}
              price={price}
              discount_percentage={discount_percentage}
            />
          </div>
</div>*/}

      </form>

    </Layout>
  )
}

const PaymentForm = (props) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
)

export default PaymentForm