import React from "react";
import Layout from "../Layout";
import {Link} from "react-router-dom";
import GetCustomer from "../../Common/ApiCalls/GetCustomer";
import EmailValidator from "email-validator";
import SendNotification from "../../Common/Utils/SendNotification";
import NotificationTypeEnum from "../../Common/Models/NotificationTypeEnum";
import Account from "./Account";
import NoActiveSubscription from "./NoActiveSubscription";

class Login extends React.Component{
  state = {
    email: '',
    password: '',
    logged: false,
    user: null
  }

  handleLogin = async (event) => {
    event.preventDefault()
    if(!EmailValidator.validate(this.state.email)) {
      SendNotification(NotificationTypeEnum.Failure, 'Enter Valid Email');
      return
    }
    await GetCustomer(this.state.email, this.state.password)
      .then(r => {
        //console.log(r)
        if(r.data) {
          if(r.data.subscription_id) {
            this.setState({
              user: r.data,
              logged: true
            })
          } else {
            this.setState({
              logged: true
            })
          }
        }
        else {
          SendNotification(NotificationTypeEnum.Failure, "Email and Password Combination does not exist")
        }
      })
  }

  render() {
    if(this.state.logged) {
      if(this.state.user) {
        return (
          <Account
            user={this.state.user}
            email={this.state.email}
          />
        )
      } else {
        return (
          <NoActiveSubscription
            email={this.state.email}
          />
        )
      }
    }
    return(
      <Layout current={-1} >
        <div className="columns">
          <div className="column is-half-desktop">

            <div className="title has-text-centered">
              Sign In
            </div>

            <form
              className="form"
              onSubmit={this.handleLogin}
            >

              <div className="field is-spaced">
                <div className="control">
                  <input
                    className="input"
                    type="email"
                    value={this.state.email}
                    placeholder="Email"
                    required
                    onChange={e => this.setState({email: e.target.value})}
                  />
                </div>
              </div>

              <div className="field is-spaced">
                <div className="control">
                  <input
                    className="input"
                    type="password"
                    value={this.state.password}
                    placeholder="Password"
                    required
                    onChange={e => this.setState({password: e.target.value})}
                  />
                </div>
              </div>

              <div className="field has-text-centered is-spaced">
                <div className="control">
                  <button className="button is-large is-danger" onClick={this.submit} type="submit">
                    Sign in
                  </button>
                </div>
              </div>

              <div className="field has-text-centered is-gapped">
                <div className="control">
                  <div className="subtitle has-text-white is-uppercase">
                    Don't have an account?
                    <Link className="is-link has-text-danger" to={'/'}>
                      &nbsp;&nbsp;Sign up
                    </Link>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Login