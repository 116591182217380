import {GetRequest, PostRequest} from "./Requests";
import ApiError from "../Models/ApiError"
import get from 'lodash/get'

const GetUsers = async (userString) => {
  let apiResponse;
  apiResponse = await GetRequest("https://api.beatshine.com/beat/api/v1/b_user", {q: userString});
  //console.log(apiResponse)
  if (apiResponse.isValid()) {
    return (apiResponse.body.data.hits || []).map(({profile}) => ({
      id: get(profile, 'v2Id'),
      avatar: get(profile, 'avatar.sizes.mini'),
      name: get(profile, 'displayName'),
      username: get(profile, 'username'),
      location: get(profile, 'location'),
      badges: get(profile, 'badges'),
    }))
  } else {
    let message = `Something went wrong. Please contact support@profilebud.com. Error: ${apiResponse.error}`;
    return new ApiError(message);
  }
};

export default GetUsers;
