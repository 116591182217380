import React from "react";
import Layout from "../Layout";
import moment from "moment";
import cancelSubscription from "../../Common/ApiCalls/cancelSubscription";
import SendNotification from "../../Common/Utils/SendNotification";
import NotificationTypeEnum from "../../Common/Models/NotificationTypeEnum";

class Account extends React.Component{
  state = {
    user: this.props.user
  }

  convertToDate = (time) => {
    time = parseInt(time)
    return moment.unix(time).format('DD/MM/YY')
  }

  signOut = () => {
    window.location.reload()
  }

  cancelSubscription = async () => {
    await cancelSubscription(this.state.user.subscription_id)
      .then(r => {
        if (r.success) {
          SendNotification(NotificationTypeEnum.Failure, 'Subscription Cancelled')
          this.signOut()
        } else {
          SendNotification(NotificationTypeEnum.Success, 'Cancel Failed')
        }
      })
  }

  render() {
    //console.log(this.props)
    let user = this.props.user;
    return(
      <Layout current={-1}>
        <div className="card is-darkish">

          <div className="card-content">

            <div className="field is-gapped">
              <span className="label is-inline-block has-text-white">Email:&nbsp;</span>
              <span className="has-text-weight-normal">{this.props.email}</span>
            </div>

            <div className="field is-gapped">
              <span className="label is-inline-block has-text-white">PLAN:&nbsp;</span>
              <span className="has-text-weight-normal">{user.plan_name}</span>
            </div>

            <div className="field is-gapped">
              <span className="label is-inline-block has-text-white">ACCOUNTS ACTIVATED ON:&nbsp;</span>
              <span className="has-text-weight-normal">{user.beat_star_accounts.length}</span>
            </div>

            <div className="field is-gapped">
              <span className="label is-inline-block has-text-white">ACCOUNT NAMES:&nbsp;</span>
              {user.beat_star_accounts.map((item) => (
                <span className="has-text-weight-normal">{item.username}&nbsp;</span>
              ))}
            </div>

            <div className="field is-gapped">
              <span className="label is-inline-block has-text-white">RENEWS ON:&nbsp;</span>
              <span className="has-text-weight-normal">{this.convertToDate(user.valid_till)}</span>
            </div>
          </div>

        </div>

        <div className="field">
          <div className="control">
              <div className="is-fullwidth has-text-left button" onClick={this.signOut}>
                <div className="is-size-4-desktop has-text-left has-text-red is-size-5-tablet is-size-6-mobile">
                  Sign out
              </div>
            </div>
          </div>
        </div>

        <div className="field">
          <div className="control">
              <div className="cancel is-fullwidth has-text-left button" onClick={this.cancelSubscription}>
                <div className="is-size-4-desktop is-size-5-tablet is-size-6-mobile">
                  Cancel Subscription
                </div>
              </div>
          </div>
        </div>

      </Layout>
    )
  }
}

export default Account