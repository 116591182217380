import React from "react";
import {Route, Switch} from "react-router-dom";
import SignUp from "./SignUp/index";
import Login from "./Login";
import Helmet from '../Components/icon/Helmet'
import ReactGA from 'react-ga'
import Drift from 'react-driftjs'

class Home extends React.Component{

  componentDidMount() {
    ReactGA.initialize('UA-172324333-2', {
      debug: false,
      titleCase: false
    });
  }

  render() {
    return(
      <React.Fragment>

        <Helmet />

        {/*<Drift appId="9wha9pe5ysdg" /> */}

        <Switch>

          <Route
            exact
            path={`/`}
            render={props => (
              <SignUp {...props} />
            )}
          />

          <Route
            exact
            path={'/sign-in'}
            render={props => (
              <Login {...props} />
            )}
          />

        </Switch>

      </React.Fragment>
    )
  }
}

export default Home