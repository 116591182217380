import React from "react";

export function getRoundedPrice (price) {
  price = (price/100).toFixed(2)
  return price
}

export const TotalPriceCard = ({price, coupon, plan_name, valid, nextPageFunction, nextExist, discount_percentage, months}) => {

  function getColor (months) {
    if(months === 1) {return '#BEBEBE'}
    else return '#60E4FF'
  }

  return(
    <div
      className="column word-break has-text-centered"
      style={{
        marginTop: '5%'
      }}
    >
      <div style={{display: 'grid'}}>
        <div
          className="card has-text-centered pricing-card"
          style={{
            background: getColor(months)
          }}
        >
          {plan_name ? (
            <div
              className="title text-roboto-medium pricing-card-header is-size-1-widescreen is-size-3-desktop is-size-4-tablet"
              style={{
                /*              backgroundColor: getColor(product.name)+'39'*/
              }}
            >
              <div
                className="text-roboto is-uppercase title has-text-weight-bold is-size-5-tablet is-size-6-mobile is-size-4-desktop pricing-title"
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  marginBottom: '-20px',
                  borderRadius: '11px'
                }}
              >
                {coupon?(
                  <p className="is-size-7-mobile has-text-weight-medium is-size-6-tablet">
                    <s className="has-text-grey-light ">{getRoundedPrice(price)}</s>
                    &nbsp;Coupon Applied
                    <br />
                    <br />
                  </p>
                ):null}
                {"$ " + getRoundedPrice(price*(1-(discount_percentage/100))) + (months===12 ? "" : " /MONTH")}
              </div>
            </div>
          ) : null}
          <div className="has-text-centered is-uppercase" style={{ boxShadow: "0 0" }}>
            <div className="is-padded-top has-text-white has-text-weight-bold is-padded-bottom title has-text-weight-bold is-size-6-mobile">
              {plan_name ? plan_name : 'Select a plan'}
            </div>
          </div>
        </div>
        {nextExist ? (
          <div className={`is-padded-top pricing-card is-not-selected ${valid ? 'cancel' : ''}`}>
            <button
              disabled={!valid}
              onClick={()=>nextPageFunction()}
              className="button is-large card-button word-break"
              style={{
                backgroundColor: 'inherit'
              }}
            >
              <div className="subtitle has-text-weight-bold is-size-7-mobile is-spaced">
                NEXT
              </div>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  )
}