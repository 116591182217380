import React from 'react';

class SelectedItemCard extends React.Component {
	render() {
		const { avatar, title, subtitle } = this.props;

		return (
			<div className="columns is-mobile is-vcentered">
				<div className="avatar selected-item-image" style={{marginLeft: '15px'}}>
					{avatar ? <img style={{ borderRadius: '50%', width: '100%' }} src={avatar} alt='avatar' /> : ''}
				</div>
				<div className="column" style={{ marginLeft: '10px' }}>
					<div className="is-size-5 has-text-weight-bold">{title}</div>
					<div className="is-size-6 has-text-grey">{subtitle}</div>
				</div>
			</div>
		);
	}
}

export default SelectedItemCard;
