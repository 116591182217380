import React, {useState} from "react";
import logo from '../Common/Images/beatshine-logo-full.png'
import {Link} from "react-router-dom";
import bulmaCollapsible from "@creativebulma/bulma-collapsible/src/js";
import { store } from 'react-notifications-component'

const Navbar = ({sign}) => {
  const [active, setActive] = useState(false)

  function close() {
    setActive(false)
  }

  return(
    <nav className="navbar">

      <SupportModal 
        active={active}
        close={close}
      />

      <div className="container">
        <div className="navbar-brand">
          <div className="navbar-item">
            <a href={'/'}>
              <img
                src={logo}
                alt={'Beatshine Logo'}
              />
            </a>
          </div>
        </div>

        {sign ? (
          <div className="navbar-end">
            <div className="navbar-item">
              <button onClick={()=>setActive(true)} className="button navbar-button">
                Support
              </button>
            </div>
            <div className="navbar-item">
              <Link to="/sign-in" className="button navbar-button">
                Sign In
              </Link>
            </div>
          </div>
        ) : null}

      </div>

      </nav>
    )
}

const Contact = () => {

  function submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        store.addNotification({
          title: 'Success',
          message: 'Thanks for contacting us :)',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ["animated", "fadeIn"],
          animationOut: ['animated', "faeOut"],
          dismiss: {
            duration: 5000,
            onScreen: false
          }
        })
      } else {
        store.addNotification({
          title: 'Oops',
          message: 'There was an error :(',
          type: 'error',
          insert: 'top',
          container: 'top-right',
          animationIn: ["animated", "fadeIn"],
          animationOut: ['animated', "faeOut"],
          dismiss: {
            duration: 5000,
            onScreen: false
          }
        })
      }
    };
    xhr.send(data);
  }

  return (
    <div>
      <form
        name="contact"
        onSubmit={submitForm}
        action="https://formspree.io/moqkygaa"
        method="POST"
      >

        <div className="field is-horizontal">

          <div className="control">
            <input
              className="input"
              type="text"
              name="name"
              id="name"
              required
              placeholder="First Name"
            />
          </div>

          <div className="is-vertical">&nbsp;</div>

          <div className="control">
            <input
              className="input"
              type="text"
              name="name"
              id="name"
              placeholder="Last Name"
            />
          </div>

        </div>

        <div className="field">
          <div className="control">
            <input
              className="input"
              type={"email"}
              name="email"
              id={"email"}
              required
              placeholder="Your Email"
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <textarea
              className="textarea"
              name={"message"}
              id={"message"}
              required
              placeholder="Message"
            />
          </div>
        </div>

        <div className="field">
          <button className="button is-link" type="submit">
            Send
          </button>
        </div>

      </form>
    </div>
  )
}

export const SupportModal = ({active, close}) => {
  return(
    <ModalComponent
      active={active}
      close={close}
    >
      <Contact />
    </ModalComponent>
  )
}

const ModalComponent = ({children, active, close}) => {

  if(!active) {
    return null
  }
  return (
    <div className={`modal ${active ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={close} />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Contact Us</p>
        </header>
        <section className="modal-card-body">
          <div className="content">
            {children}
          </div>
        </section>
        <footer className="modal-card-foot flex-end">
          <a href="#faq" className="button" onClick={close}>Go Back</a>
        </footer>
      </div>
    </div>
  )
}

export default Navbar