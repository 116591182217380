import React from 'react';
import { Button } from 'antd';
import Layout from "../../Components/Layout";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, errorInfo) {
		this.setState({ hasError: true });
		console.log('error', error);
		console.log('errorInfo', errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<Layout current={-1}>
					<h1>
						Well, This is bad. Something went wrong.
						<br />
						Also, try Reloading.
						<Button onClick={() => { window.location.reload(); }} type="primary">Reload</Button>
					</h1>
				</Layout>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
