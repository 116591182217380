export const API_URL = "https://api.beatshine.com/beat/api/v1/"

export const API_URL_v2 = "https://api.beatshine.com/beat/api/v2/"

export function getPath(url) {
  return API_URL+url
}

export function getNewPath(url) {
  return API_URL_v2 + url
}