import React, { useEffect } from "react";
import Layout from "../Layout";
import moment from 'moment'

const Complete = ({accountInformation,email, plans, accounts}) => {
  let selectedPlan;
  for(let i=0;i<plans.length;i++) {
    if(accountInformation.priceId === plans[i]['stripe_price_id']) {
      selectedPlan = plans[i];
      break;
    }
  }
  let current_period_start;
  let current_period_end;
  if(accountInformation.invoice) {
    let invoice = accountInformation.invoice;
    current_period_end = invoice.period_end;
    current_period_start = invoice.period_start;
  }
  else {
    current_period_start = accountInformation.subscription.current_period_start;
    current_period_end = accountInformation.subscription.current_period_end;
  }

  function convertToDate(time) {
    time = parseInt(time)
    return moment.unix(time).format('DD/MM/YY')
  }

  useEffect(()=>{
    window.open('https://beatshine.com/thank-you', '_blank')
  }, [])

  return(
    <Layout>
      <div className="section">
        <div className="title has-text-centered has-text-danger">
          Thank you!
        </div>
        <div className="subtitle has-text-centered">
          Your subscription has been successfully activated
        </div>

          <div className="card is-darkish">
            <div className="card-content">

              <div className="field is-gapped">
                <span className="label is-inline-block has-text-white">Email:&nbsp;&nbsp;</span>
                <span className="has-text-weight-normal">{email}</span>
              </div>

              <div className="field is-gapped">
                <span className="label is-inline-block has-text-white">PLAN:&nbsp;&nbsp;</span>
                <span className="has-text-weight-normal">{selectedPlan.name}</span>
              </div>

              <div className="field is-gapped">
                <span className="label is-inline-block has-text-white">ACCOUNTS ACTIVATED ON:&nbsp;&nbsp;</span>
                <span className="has-text-weight-normal">{selectedPlan.accounts}</span>
              </div>

              <div className="field is-gapped">
                <span className="label is-inline-block has-text-white">ACCOUNT NAMES:&nbsp;&nbsp;</span>
                {accounts.map((item) => (
                  <span className="has-text-weight-normal">@{item.username}&nbsp;</span>
                ))}
              </div>

              <div className="field is-gapped">
                <span className="label is-inline-block has-text-white">SUBSCRIBED ON:&nbsp;&nbsp;</span>
                <span className="has-text-weight-normal">{convertToDate(current_period_start)}</span>
              </div>

              <div className="field is-gapped">
                <span className="label is-inline-block has-text-white">RENEWS ON:&nbsp;&nbsp;</span>
                <span className="has-text-weight-normal">{convertToDate(current_period_end)}</span>
              </div>

            </div>
          </div>
      </div>
    </Layout>
  )
}

export default Complete