import React from "react";
import Navbar from "./Navbar";

const Layout = ({children, sign}) => {
  return(
    <>
      <Navbar sign={sign} />
      <main>
        <div className="section section-padding-less">
          <div className="container">
            {children}
          </div>
        </div>
      </main>
    </>
  )
}

export default Layout