import React from "react";
import Layout from "../Layout";
import {Link, Redirect} from "react-router-dom";
import EmailValidator from 'email-validator'
import ReactGA from 'react-ga'
import SendNotification from "../../Common/Utils/SendNotification";
import NotificationTypeEnum from "../../Common/Models/NotificationTypeEnum";

class SignUpForm extends React.Component{
  state = {
   email: '',
   password: '',
    confirmPassword: '',
    valid: false,
  }

  submit = (e) => {
    e.preventDefault()
    if(!EmailValidator.validate(this.state.email)) {
      SendNotification(NotificationTypeEnum.Failure, 'Enter Valid Email');
      return
    }
    if(this.state.password.length < 6) {
      SendNotification(NotificationTypeEnum.Failure, 'Password should be at least 6 characters long')
      return
    }
    if(this.state.password!==this.state.confirmPassword) {
      SendNotification(NotificationTypeEnum.Failure, 'Passwords does not match')
      return
    }
    this.props.handleEmail(this.state.email)
    this.props.handlePassword(this.state.password)
    ReactGA.event({
      category: 'Sign up',
      action: 'Press Sign Up button',
      label: `Email:  + ${this.state.email}`
    })
    this.props.nextPage()
  }

  render() {
    return(
      <Layout current={1} >
        <div className="columns">
          <div className="column is-half-desktop is-two-thirds-tablet">

            <div className="title has-text-centered">
              Customer Information
            </div>

            <form
              className="form"
              onSubmit={this.submit}
            >

              <div className="field is-spaced">
                <div className="control">
                  <input
                    className="input"
                    type="email"
                    value={this.state.email}
                    placeholder="Email"
                    required
                    onChange={e => this.setState({email: e.target.value})}
                  />
                </div>
              </div>

              <div className="field is-spaced">
                <div className="control">
                  <input
                    className="input"
                    type="password"
                    value={this.state.password}
                    placeholder="Password"
                    required
                    onChange={e => this.setState({password: e.target.value})}
                  />
                  {
                    this.state.password.length>0 && this.state.password<6 ?
                      <label className="label has-text-grey-light has-text-centered">
                        Password should be at least 6 characters long
                      </label>
                      : null
                  }
                </div>
              </div>

              <div className="field is-spaced">
                <div className="control">
                  <input
                    className="input"
                    type="password"
                    required
                    value={this.state.confirmPassword}
                    placeholder="Confirm Password"
                    onChange={e => this.setState({confirmPassword: e.target.value})}
                  />
                  {
                    (this.state.confirmPassword.length>0 && this.state.password!==this.state.confirmPassword) ?
                      <label className="label has-text-red has-text-centered">
                        Passwords doesn't match
                      </label>
                      : null
                  }
                </div>
              </div>

              <div className="field has-text-centered is-gapped">
                <div className="control">
                  <button className="button is-large cancel" type="submit" >
                    Submit
                  </button>
                </div>
              </div>

              <div className="field has-text-centered is-gapped">
                <div className="control">
                  <div className="subtitle has-text-white is-uppercase has-text-weight-bold">
                    Already have an account?
                    <Link className="is-link has-text-danger" to={'/sign-in'}>
                      &nbsp;&nbsp;Sign in
                    </Link>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SignUpForm