import React, {useEffect} from "react";

function Product({product, handleClick, selected}) {
  function getRoundedPrice (price) {
    price = (price/100).toFixed(2)
    return price
  }
  
  function getColor (name) {
    name = name.toUpperCase()
    if(name === 'PREMIUM MONTHLY') return '#BEBEBE'
    else if(name === 'SILVER') return '#BEBEBE'
    else return '#60E4FF'
  }

  return(
    <div className="column is-two-fifths-desktop is-half-tablet has-text-centered cursor-pointer" key={product.id} onClick={()=>handleClick(product.id)}>
      <div className="card pricing-card">
        <div className="card-content is-uppercase">
          <div
            style={{background: getColor(product.name)}}
            className="card-header is-block product-card-header"
          >
            <div className="has-text-centered">
              <div className="is-size-4-tablet is-size-6-mobile is-size-3-desktop">
                {product.name}
              </div>
            </div>
          </div>
          <div className="is-padded-top is-padded-bottom subtitle has-text-weight-bold is-size-6-mobile has-text-black">
            {product.time_in_month} Month Access
          </div>
          {
            product.time_in_month === 12 ? (
              <>
              <div className="is-padded-bottom subtitle has-text-weight-bold is-size-6-mobile has-text-black">
                1 Time Payment
              </div>
              <div className="is-padded-bottom subtitle has-text-weight-bold is-size-6-mobile" style={{color: '#4EDEF6'}}>
                Save 45%
              </div>
              </>
            ) : (
              <>
              <div className="is-padded-bottom subtitle has-text-weight-bold is-size-6-mobile has-text-black">
                Charged Monthly
              </div>
              <div className="is-padded-bottom subtitle has-text-weight-bold is-size-6-mobile" style={{color: '#4EDEF6'}}>
                &nbsp;
              </div>
              </>
            )
          }
        </div>
      </div>
      {
        selected ? (
          <div className="cancel is-not-selected is-padded-top less pricing-card">
              <div className="button is-large word-break card-button">
                <div className="subtitle has-text-weight-bold is-size-6-mobile">
                  {"$ " + getRoundedPrice(product.price_in_cents)} {product.time_in_month===12 ? "" : "/MONTH"}
                                  {product.time_in_month === 12 ? (
                    <>
                    <span className="is-size-6" >
                      ({ "~ $" + Math.floor(getRoundedPrice(product.price_in_cents/12)) + " /month"})
                    </span>
                    </>
                  ) : null}
                </div>
            </div>
          </div>
        ) : (
          <div className="is-padded-top less pricing-card is-not-selected">
              <div className="button is-large card-button word-break">
                <div className="subtitle has-text-weight-bold is-size-6-mobile is-spaced">
                  {"$ " + getRoundedPrice(product.price_in_cents)} {product.time_in_month===12 ? "" : "/MONTH"}
                  {product.time_in_month === 12 ? (
                    <>
                    <span className="is-size-6" >
                      ({ "~ $" + Math.floor(getRoundedPrice(product.price_in_cents/12)) + " /month"})
                    </span>
                    </>
                  ) : null}
                </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default Product