import React, {useState} from "react";
import Layout from "../Layout";

const NoActiveSubscription = ({email}) => {

  function signOut() {
    window.location.reload()
  }

  return(
    <Layout>
      <div className="card is-darkish">

        <div className="card-content">

          <div className="field is-gapped">
            <span className="label is-inline-block has-text-white">Email:&nbsp;</span>
            <span className="has-text-weight-normal">{email}</span>
          </div>

          <div className="subtitle has-text-white">
            No Active Subscription
          </div>

        </div>

      <div className="field">
        <div className="control">
          <div className="is-fullwidth has-text-left button" onClick={signOut}>
            <div className="is-size-4-desktop has-text-left has-text-red is-size-5-tablet is-size-6-mobile">
              Sign out
            </div>
          </div>
        </div>
      </div>

{/*      <div className="field">
        <div className="control">
          <div className="cancel is-fullwidth has-text-left button" onClick={cancelSubscriptionFunction}>
            <div className="is-size-4-desktop is-size-5-tablet is-size-6-mobile">
              Cancel Subscription
            </div>
          </div>
        </div>
      </div>*/}
      </div>

    </Layout>
  )
}

export default NoActiveSubscription