import React from 'react';
import {
  Spin, notification, AutoComplete, Empty,
} from 'antd';

import debounce from 'lodash/debounce';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

import SearchItemCard from './SearchItemCard';
import SelectedItemCard from "./SelectedItemCard";
import GetUsers from "../../Common/ApiCalls/GetUsers";
import SendNotification from "../../Common/Utils/SendNotification";
import NotificationTypeEnum from "../../Common/Models/NotificationTypeEnum";

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMusicians: this.props.selectedMusicians,
      result: [],
      query: ''
    };

    this.onSearch = debounce(this.onSearch.bind(this), 600);
    this.onSelectUser = this.onSelectUser.bind(this);
  }

  findItem(id) {
    const idKey = this.state.searchType === 'musician' ? 'id' : 'track_id';
    return find(this.state.result, [idKey, Number.parseInt(id, 10)]);
  }

  onSelectUser(key, { user }) {
    let selectedMusicians = this.props.selectedMusicians.slice();
    const i = findIndex(selectedMusicians, ['id', user.id]);
    if (i !== -1) return;

    selectedMusicians.push(user)
    this.updateUsers(selectedMusicians)
  }

  onRemoveUser(userId) {
    let selectedMusicians = this.props.selectedMusicians.slice();
    selectedMusicians = selectedMusicians.filter((user) => user.id !== userId);
    this.updateUsers(selectedMusicians)
  }

  updateUsers(selectedMusicians) {
    console.log(selectedMusicians)
    if(this.props.maxUsers === 0) {
      SendNotification('No plan selected','Select a Plan to Add Users')
      return;
    }
    if(selectedMusicians.length > this.props.maxUsers) {
      SendNotification(NotificationTypeEnum.Success, 'Upgrade Plan to Add more users');
      return
    }
    this.props.handleAccounts(selectedMusicians)
  }

  async onSearch(query) {
    this.setState({ query });
    if (!query) return;

    this.setState({ fetching: true, result: [] });
    const result = await GetUsers(query).catch((err) => {
      notification.error({
        message: err.message,
        description: 'Please login on beatstars',
        duration: null,
      });
      return [];
    });
    this.setState({ fetching: false, result });
  }

  render() {
    const selectedMusicians = this.props.selectedMusicians;

    const SearchItem = (user) => (
      <SearchItemCard
        key={user.id}
        avatar={user.avatar}
        title={user.name}
        subtitle={`@${user.username}`}
      />
    );

    const MusicianCard = (user) => (
      <SelectedItemCard
        closable={true}
        key={user.id}
        id={user.id}
        avatar={user.avatar}
        title={user.name}
        subtitle={`@${user.username}`}
        onRemove={() => this.onRemoveUser(user.id)}
      />
    );

    let notFoundContent = null;
    if (this.state.fetching) {
      notFoundContent = <Spin size="small"/>;
    } else if (this.state.query && !this.state.result.length) {
      notFoundContent = 'Not Found';
    }
    return (
      <div className="search-box">
        {
          selectedMusicians.length < this.props.maxUsers ? (
            <div className="search-bar">
              <div>
                Select your beatstars account to use with Beatshine
                </div>
            <AutoComplete
              notFoundContent={notFoundContent}
              size="large"
              placeholder="johndoe"
              options={this.state.result.map((user) => ({
                value: user.username,
                user,
                label: SearchItem(user),
              }))}
              onSearch={this.onSearch}
              onSelect={this.onSelectUser}
            />
          </div>
          ) : null
        }
        {
          selectedMusicians.length
            ? <div className="columns is-multiline search-selection">
              {selectedMusicians.map((musician) => MusicianCard(musician))}
            </div>
            : null
        }
      </div>
    );
  }
}

export default SearchBox;
