import React from "react";
import {Helmet} from "react-helmet";
import PropTypes from 'prop-types'

const metaDescription = "Payment Portal of Beatshine"
const defaultTitle = 'Beatshine Payment Portal'

function SEO({description, lang, meta, title}) {
  return (
    <Helmet>
      <meta lang={lang} />
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <link rel="apple-touch-icon" sizes="180x180" href="./apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="./favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="./favicon-16x16.png" />
      <link rel="manifest" href="./site.webmanifest" />
      <meta name="viewport" content="'width=device-width, initial-scale = 1.0, maximum-scale=1.0, user-scalable=no'" />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: metaDescription,
  title: defaultTitle
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string
}

export default SEO
