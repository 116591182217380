import React from "react";
import SignUpForm from "./SignUpForm";
import Prices from "../Prices";
import PaymentForm from "../Prices/PaymentForm";
import Complete from "../Prices/Complete";
import GetPlansStripe from "../../Common/ApiCalls/GetPlansStripe";
import Loader from '../Prices/Loader'

class SignUp extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      step: -1,
      price_id: null,
      accountInformation: null,
      users: [],
      plans: [],
      plan_name: '',
      coupon_code: '',
      price: 0,
      discount_percentage: 0,
    }
  }

  goToNextPage = () => {
    this.setState({step: this.state.step+1})
  }

  gotToPreviousBack = () => {
    this.setState({step: this.state.step-1})
  }

  handleEmail = (email) => {
    this.setState({email})
  }
  handlePassword = (password) => {
    this.setState({password})
  }
  handlePriceSelect = (price_id,price, planName,) => {
    this.setState({
      price_id,
      price,
      planName
    })
  }
  handleCoupon = (coupon_code, discount_percentage) => {
    this.setState({
      coupon_code,
      discount_percentage
    })
  }
  handleUsers = (users) => {
    this.setState({users})
  }
  handleAccount = (accountInformation) => {
    this.setState({accountInformation})
  }
  getPlans = async () => {
    return await GetPlansStripe()
  }

  componentDidMount () {
    this.getPlans().then(r => this.setState({plans: r, step: 0}))
  }

  render() {
    let email = this.state.email;
    let step = this.state.step;
    let contentComponent;
    switch (step) {
      case -1:
        contentComponent = (
          <Loader 
            fullscreen 
          />
        )
      case 0:
        contentComponent = (
          <Prices
            nextPage={this.goToNextPage}
            handleUsers={this.handleUsers}
            handlePriceChange={this.handlePriceSelect}
            handleCoupon={this.handleCoupon}
            plans={this.state.plans}
          />
        );
        break;
      case 1:
        contentComponent = (
          <SignUpForm
            nextPage={this.goToNextPage}
            handleEmail={this.handleEmail}
            handlePassword={this.handlePassword}
          />
        );
        break;
      case 2:
        contentComponent = (
          <PaymentForm
            nextPage={this.goToNextPage}
            handleAccount={this.handleAccount}
            email={email}
            password={this.state.password}
            price_id={this.state.price_id}
            coupon_code={this.state.coupon_code}
            price={this.state.price}
            discount_percentage={this.state.discount_percentage}
            plan_name={this.state.plan_name}
            beat_accounts={this.state.users}
            goBack={this.gotToPreviousBack}
          />
        );
        break;
      case 3:
        contentComponent = (
          <Complete
            accountInformation={this.state.accountInformation}
            email={email}
            plans={this.state.plans}
            accounts={this.state.users}
          />
        )
    }
    return(
      <div>
        {contentComponent}
      </div>
    )
  }
}

export default SignUp